import React from 'react';
import {
  BarcodeOutlined,
  CarOutlined, CarryOutOutlined, FileSearchOutlined, QrcodeOutlined, UserOutlined,
} from '@ant-design/icons';
import {BiCopy, BiTransfer, BiTransferAlt} from 'react-icons/bi';
import { BsBoxArrowUp, BsCardList } from 'react-icons/bs';
import { VscLiveShare } from 'react-icons/vsc';
import { MdOutlineAccountBalanceWallet, MdOutlineRequestPage } from 'react-icons/md';
import { RiFolderTransferLine } from 'react-icons/ri';
import { AiFillCreditCard } from 'react-icons/ai';
import { FaRegCreditCard } from 'react-icons/fa';
import {
  Sider,
  Logo,
  TecpayLogo,
  Menu,
  SubMenu,
  Item,
  Link,
} from '../styles';
import { ISideMenuProps } from '../types';
import { RoutesEnum } from '../../../routes/types';
import {HiOutlineAdjustments} from "react-icons/hi";

const SideMenu: React.FC<ISideMenuProps> = (props: ISideMenuProps) => {
  const { collapsed } = props;
  const selectedBusiness = localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) : null;
  return (
    <Sider
      width={200}
      className="site-layout-background"
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{backgroundColor: '#0e2b27'}}
    >
      <Logo to="/dashboard">
        <TecpayLogo alt="Logo-Valori" />
      </Logo>
      <Menu
        mode="inline"
        theme="dark"
        style={{backgroundColor: '#0e2b27'}}
      >
        <Item icon={<UserOutlined />} key="">
          <a target="_blank" rel="noreferrer" href="https://valoriapp.dbs.moneyp.com.br/login">
            <b>Acesso à conta</b>
          </a>
        </Item>
        <Item icon={<VscLiveShare />} key="painelVendas">
          <Link to="/tecpay/admin/painel-de-vendas">
            Vendas
          </Link>
        </Item>
        <Item icon={<VscLiveShare />} key="painelRecebiveis">
          <Link to="/tecpay/admin/painel-de-recebiveis">
            Recebíveis
          </Link>
        </Item>
        {selectedBusiness?.allowPaymentLink && (
          <SubMenu key="assinatura" title="Assinaturas" icon={<BiTransfer/>}>
            <Item icon={<VscLiveShare/>} key="listarAssinaturas">
              <Link to="/listar-assinaturas">
                Histórico de assinaturas
              </Link>
            </Item>
            <Item icon={<VscLiveShare/>} key="cadastrarAssinaturas">
              <Link to="/cadastrar-assinaturas">
                Gerar assinaturas
              </Link>
            </Item>
          </SubMenu>
        )}
        <SubMenu key="simulador" title="Simulador" icon={<BiTransfer />}>
          <Item  key="simulador-recebimento">
            <Link to="/simulador/recebimento">
              Quero receber
            </Link>
          </Item>
          <Item key="simulador-venda">
            <Link to="/simulador/venda">
              Quero vender por
            </Link>
          </Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default SideMenu;
