import React, {
  useReducer,
  useState,
  useCallback,
  useEffect,
} from 'react';
import FormFilter from './components/filter';
import {
  Container,
  Button,
} from './styles';
import { T2 } from '../../../styles/titles';
import {getAllLiveTransactions, getAllLiveTransactionsRecebiveis} from './actions';
import {
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../global/dataTransferObjects/transacoesTempoReal';
import LiveTable from './components/liveTable';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ZeroState from '../../../components/ZeroState';
import {formatDate} from "../../../utils/formatDate";
import {getAllVendas} from "../../vendas/actions";
import {getFileDownload} from "../painelDeVendas/actions";
import {InitialState, Reducer} from "../painelDeRecebiveis/actions/reducer";
import PainelDePagamentosService from "./service/PainelDePagamentosService";
const painelDePagamentosService = new PainelDePagamentosService();
const PainelDePagamentos: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>(null);
  const [pagamentos, setPagamentos] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [externalPayments, setExternalPayments] = useState<any[]>([]);
  const [internalPayments, setInternalPayments] = useState<any[]>([]);

  let today: Date = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0 ,0 ,0 ,0);

  useEffect(() => {
    setTimeout(() => {
      handleFilter({
        initialDate: formatDate(today),
        finalDate: formatDate(today),
        tipoPagamento:'0',
        businessName:''
      })
    }, 200);
  }, []);


  const handleFilter = async (filter: any) => {
    setLoading(true);
    setFilterData(filter);

    await painelDePagamentosService.getPagamentos(filter)
      .then(response => {
        setExternalPayments(response.data.records);
    });

    await painelDePagamentosService.getPainelDePagamentos(filter)
      .then((response: any) => {
        setInternalPayments(response.data.response)
    });

    setLoading(false);
  };

  const handleDownload = async (filter?: PainelVendasFilterProps) => {
    const result = await painelDePagamentosService.getFileDownload(filter);

    try {
      const objectUrl = window.URL.createObjectURL(result);
      const a = document.createElement('a');
      a.href = objectUrl;

      a.download = 'Pagamentos.xlsx';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Erro ao exportar tabela:', error);
    }
  };

  if (state.zeroState) {
    return (
      <ZeroState />
    )
  }

  useEffect(() => {
    const allPayments = externalPayments.concat(internalPayments);
    setPagamentos(allPayments);
  }, [externalPayments, internalPayments]);

  return (
    <Container>
      <LoadingOverlay
        show={loading}
        relative
      />
      <T2>Painel De Pagamentos</T2>
      <FormFilter setLoading={setLoading} loading={loading} liveTransactions={internalPayments} externalLiveTransactions={externalPayments}  onFormSubmit={handleFilter} filter={filterData} />
      <Button onClick={() => {handleDownload(filterData) }} style={{marginBottom: '25px', marginRight: '24px', width: '150px', alignSelf: 'start'}}>Download Xlsx</Button>
      <LiveTable setPagamentos={setPagamentos} liveTransactions={pagamentos} />
    </Container>
  );
};
export default PainelDePagamentos;
